import React, { } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import './Home.css';

import descImg from "../images/DescBG.png";


const Home = () => {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    return (
        <Container fluid className=' noPadding'>
            <Row className='sectionHeader'>
                <div className='verticalAlign'>
                    <div className='logoCenterGradient'>
                        <Image src="assets/LogoSilhueta.png" className='logoMask'></Image>
                        <div className='pulseRing ring0'></div>
                        <div className='pulseRing ring1'></div>
                        <div className='pulseRing ring2'></div>
                    </div>
                </div>
            </Row>
            <Row className='sectionMotto' style={{ backgroundImage: `url(${descImg})` }}>
                {/* <Image src="/assets/Motto.png" className='mottoImg'></Image> */}
                <h1 className='mottoTitle'>
                    Treemaru Studio solves WEB3 challenges, provides experiences and educates our users through blockchain products.
                </h1>
            </Row>
            <Row className='sectionProjectsTitle bigMarginTop'>
                <h2 className='underline'>Our Projects</h2>
            </Row>

            <Container fluid className='sectionProjects noPadding'>
                <Row className='projectRow'>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }} className="textLeft projectDesc">
                                    <div className='projectDescDiv'>
                                        <h2>Maru University</h2>
                                        <h4 className='colorPink'>Online School</h4>
                                        <p>
                                            <br></br>
                                            With Maru University, we want to educate and bring more people into the NFT ecosystem. Students will get access to lessons about different aspects of NFTS: How to make a 'safe' wallet; How do you create a smart contract; How do you read a smart contract; What you should look for when you find a new project; How to use the different platforms, such as Aspen, Manifold, and marketplaces.
                                            <br></br>
                                            These lessons will go beyond trading and blockchain programming: you will be able to learn about digital art, generative art, game art and development, and much more.
                                            <br></br>
                                            <br></br>
                                        </p>
                                        <button className='buttonKnowMore' onClick={() => { openInNewTab("https://maru.university/") }}>Know More</button>
                                    </div>
                                </Col>
                                <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 0 }} className="projectLogo">
                                    <Image className='' src="/assets/maruLogo.png"></Image>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <div className='pinkStrokeDiv hideMobile' style={{ right: "90%" }}>
                        <Image fluid src="/assets/pinkStroke.png"></Image>
                    </div>
                </Row>
                <Row className='projectRow'>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: "first" }} className="textLeft projectDesc">
                                    <div className='projectDescDiv'>
                                        <h2>Senzu World</h2>
                                        <h4 className='colorPink'>NFT Game</h4>
                                        <p>
                                            <br></br>
                                            Senzu World is a fast-paced roguelite game where the players will go on adventures, and collect Creatures, Treasures and many other items across the world.
                                            <br></br>
                                            <br></br>
                                        </p>
                                        <button className='buttonKnowMore' onClick={() => { openInNewTab("https://senzuworld.io/") }}>Know More</button>
                                    </div>
                                </Col>
                                <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: "last" }} className="projectLogo">
                                    <Image className='' src="/assets/senzuworldLogo.png"></Image>
                                </Col>
                            </Row>
                        </Container>

                    </Col>

                    <div className='pinkStrokeDiv hideMobile' style={{ left: "80%" }}>
                        <Image fluid src="/assets/pinkStroke.png"></Image>
                    </div>
                </Row>
                <Row className='projectRow'>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }} className="textLeft projectDesc">
                                    <div className='projectDescDiv'>
                                        <h2>No No's NFT</h2>
                                        <h4 className='colorPink'>NFT PFP Collection</h4>
                                        <p>
                                            <br></br>
                                            No No’s  is a community-driven collectibles NFT project. We've said Yes for too long, it’s time to say some No’s. We came from a galaxy faraway and we’re conducting some stops throughout our journey. We would love for you to join us! Or No No?
                                            <br></br>
                                            <br></br>
                                        </p>
                                        <button className='buttonKnowMore' onClick={() => { openInNewTab("https://nonosnft.com/") }}>Know More</button>
                                    </div>
                                </Col>
                                <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 0 }} className="projectLogo">
                                    <Image className='' src="/assets/nonosLogo.png"></Image>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <div className='pinkStrokeDiv hideMobile' style={{ right: "90%" }}>
                        <Image fluid src="/assets/pinkStroke.png"></Image>
                    </div>
                </Row>
                <Row className='projectRow'>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }} className="textLeft projectDesc">
                                    <div className='projectDescDiv'>
                                        <h2>TreeLogin</h2>
                                        <h4 className='colorPink'>Security DAPP</h4>
                                        <p>
                                            <br></br>
                                            TreeLogin is a Web3 Security Dapp that allows collectors to prove ownership without signing with Cold Wallets, keeping all portfolio, blue chip nfts and currency safe!
                                            All you need is a hot wallet, and the TreeLogin dapp will fetch and store your multiple cold wallets onto the blockchain without signing with them!
                                            <br></br>
                                            <br></br>
                                        </p>
                                        <button className='buttonKnowMore' onClick={() => { openInNewTab("https://treelogin.com/") }}>Know More</button>
                                    </div>
                                </Col>
                                <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }} className="projectLogo">
                                    <Image className='' src="/assets/treeloginLogo.png"></Image>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <div className='pinkStrokeDiv hideMobile' style={{ left: "94%" }}>
                        <Image fluid src="/assets/pinkStroke.png"></Image>
                    </div>
                </Row>

            </Container>

            <Row className='sectionTeam marginTop'>

                <Row className='sectionProjectsTitle marginTop'>
                    <h2 className='underline'>The Team</h2>
                </Row>
                <Row className='marginTop'>

                    <Col sm={6} md={4} className="">
                        <Row>
                            <Col className="teamMemberDiv">

                                <Image className='teamPhoto' src="assets/Alfden.png"></Image>
                                <div className='teamBorder'>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='smallMarginTop'>
                                <h3>André Ferreira</h3>
                                <h4 className='cursorPointer' onClick={() => { openInNewTab('https://twitter.com/alf_den') }}>@alf_den</h4>
                                <p>Co-Founder
                                    <br></br>
                                    Artist Director</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col sm={6} md={4}>
                        <Row>
                            <Col className="teamMemberDiv">

                                <Image className='teamPhoto' src="assets/Doxia.png"></Image>
                                <div className='teamBorder'>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='smallMarginTop'>
                                <h3>Luís Almeida</h3>
                                <h4 className='cursorPointer' onClick={() => { openInNewTab('https://twitter.com/doxia_eth') }} >@doxia_eth</h4>
                                <p>Co-Founder
                                    <br></br>
                                    Developer & 3D/VFX Artist</p>
                            </Col>
                        </Row>


                    </Col>
                    <Col sm={6} md={4} className="">
                        <Row>
                            <Col className="teamMemberDiv">

                                <Image className='teamPhoto' src="assets/Oxxyy.png"></Image>
                                <div className='teamBorder'>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='smallMarginTop'>
                                <h3>Dan Kileen</h3>
                                <h4 className='cursorPointer' onClick={() => { openInNewTab('https://twitter.com/Oxxyy13') }}>@Oxxyy13</h4>
                                <p>Co-Founder
                                    <br></br>
                                    Community Manager</p>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row className='smallMarginTop'>
                    <Col sm={6} md={2}></Col>
                    <Col sm={6} md={4} className="">
                        <Row>
                            <Col className="teamMemberDiv">

                                <Image className='teamPhoto' src="assets/Monteiro.png"></Image>
                                <div className='teamBorder'>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='smallMarginTop'>
                                <h3>André Monteiro</h3>
                                <h4 className='cursorPointer' onClick={() => { openInNewTab('https://twitter.com/andrelvmonteiro') }}>@andrelvmonteiro</h4>
                                <p>Illustrator
                                    <br></br>
                                    Game Designer</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col sm={6} md={4} className="">
                        <Row>
                            <Col className="teamMemberDiv">

                                <Image className='teamPhoto' src="assets/Nati.png"></Image>
                                <div className='teamBorder'>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='smallMarginTop'>
                                <h3>Natielle Jardim</h3>
                                <h4 className='cursorPointer' onClick={() => { openInNewTab('https://twitter.com/Natiikat') }}>@Natiikat</h4>
                                <p>Illustrator
                                    <br></br>
                                    Concept Artist</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col sm={6} md={2}></Col>

                </Row>

            </Row>
            <Row className='sectionJoin bigMarginTop'>
                <Col xs={12}>
                    <h1>Join the <span className='underline'>tree</span>
                        <br></br>
                        work with us
                    </h1>
                </Col>
                <Col sm={4} className="smallMarginTop" >
                    <p className='underlineText'>join the team</p>
                    <a target="_blank" rel="noopener noreferrer" hreflang="x-default" href="mailto:jobs@treemarustudio.com" className='smallText footerLinks'>jobs@treemarustudio.com</a>

                </Col>
                <Col sm={4} className="smallMarginTop">
                    <p className='underlineText'>let's talk</p>
                    <a target="_blank" rel="noopener noreferrer" hreflang="x-default" href="https://calendly.com/treemarustudio" className='smallText footerCallBtn'>Book a Call</a>
                </Col>
                <Col sm={4} className="smallMarginTop">
                    <p className='underlineText'>send a mail</p>
                    <a target="_blank" rel="noopener noreferrer" hreflang="x-default" href="mailto:project@treemarustudio.com" className='smallText footerLinks'>project@treemarustudio.com</a>

                </Col>
            </Row>


            <Row className="lastDiv"></Row>


        </Container>



    );
}

export default Home;