import React from 'react';
import AnimatedCursor from "react-animated-cursor"
import Home from './Home';
import CustomNavbar from './CustomNavbar';

import './App.css';

function App() {
  return (
    <div className="App">
      <CustomNavbar></CustomNavbar>
      <Home></Home>
      <AnimatedCursor
        innerSize={14}
        outerSize={12}
        color='241, 90, 36'
        outerAlpha={0.4}
        innerScale={0.7}
        outerScale={4}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
      />
    </div>
  );
}

export default App;
