import React, { } from 'react';
import { Container, Image, Navbar, Nav } from 'react-bootstrap';
import './CustomNavbar.css';

import { FaTwitter, FaDiscord } from 'react-icons/fa';

const CustomNavbar = () => {



    return (
        <div>

            <Navbar expand="lg" fixed="top" className="customNavBarStyle topBar" >
                <Container fluid>
                    <Navbar.Brand href="/">
                        <Image src="assets/LogoTreemaru.png"></Image>
                    </Navbar.Brand>

                    <Nav className="justify-content-end customNavBarStyle-links">
                        <Nav.Link href="https://twitter.com/TreemaruStudio" target="_blank" rel="noopener noreferrer"><FaTwitter></FaTwitter></Nav.Link>
                        <Nav.Link href="https://discord.gg/treemaru" target="_blank" rel="noopener noreferrer"><FaDiscord></FaDiscord></Nav.Link>



                    </Nav>
                </Container>
            </Navbar>


        </div>
    );
}

export default CustomNavbar;